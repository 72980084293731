<template>
  <div class="main">
    <div class="box">
      <div class="row">
        <div class="col-60">
          <contact-form></contact-form>
        </div>
        <div class="col-40">
          <div class="contact-info">
              <h3>Safe Group</h3>
              <p>
                  80 rue Montepy - ZA de Montepy<br />
                  69210 Fleurieux-sur-L’Arbresle
              </p>
              <div class="footer-icon-item">
                  <i class="fa-solid fa-phone footer-icon"></i> 
                  <p>+33 (0) 4 27 44 73 80</p>
              </div>
              <div class="footer-icon-item">
                  <i class="fa-solid fa-print footer-icon"></i> 
                  <p>+33 (0) 1 34 21 12 00</p>
              </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ContactForm from "./../components/ContactForm.vue";

export default {
  name: "Contact",
  components : { ContactForm }
}
</script>

<style scoped lang="scss">

.main {
    background-image: url("./../assets/bg-contact.jpg");
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
}

.col-40 {
  padding-left:20px;
  border-left:2px solid $mainColor;
}  

h3 {
  font-size:22px;
  color:$mainColor;
  font-weight: 300;
  margin:0 0 5px 0;
}

.footer-icon-item {
    display: flex;
    align-items: center;
    margin:10px 0;
}

.footer-icon {
    font-size:20px;
    color:$mainColor;
    margin-right: 10px;
    font-weight: 300;
}

.footer-icon-item p, p {
  margin:0;
}

.contact-info {
  margin-bottom:40px;
}

.row {
    padding:80px 0;
}

@media screen and (max-width:768px) {
  .col-40 {
    margin-top:80px;
  }

  .row {
    padding:40px 0;
  }
}

</style>